import React, { Fragment } from 'react'
import { connectList } from '../../config'
import ArrowImg from '../../../../../images/product/personal/connect/arrow.png'
import BannerBtns from '../../../../../components/home/bannerBtns'
import { ConnectWrap, ConnectContent, StepListWrap, StepItem } from './atom'
import { Path } from '@raylink/common/constants/path'

export function Connect() {
  return (
    <ConnectWrap>
      <ConnectContent>
        <h2 className="title">只需3步，快速远控</h2>
        <StepListWrap>
          {connectList.map((item, index) => (
            <Fragment key={item.title}>
              <StepItem>
                <div className="i-title">
                  <span>{item.index}</span>
                  {item.title}
                </div>
                <div className="i-text">{item.text}</div>
                {item.desc && <div className="i-desc">{item.desc}</div>}
                <img
                  src={item.imgSrc}
                  alt=""
                  className="i-img"
                  loading="lazy"
                />
              </StepItem>
              {index < connectList.length - 1 && (
                <img
                  src={ArrowImg}
                  alt=""
                  className="arrow-img"
                  loading="lazy"
                />
              )}
            </Fragment>
          ))}
        </StepListWrap>
        <BannerBtns
          btnInfo={{
            first: {
              text: '立即下载',
              href: Path.download,
            },
            second: {
              text: '购买会员',
              href: Path.price,
            },
          }}
          className="p-btns"
        />
      </ConnectContent>
    </ConnectWrap>
  )
}
